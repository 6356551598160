import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Headings from "../components/headings"
import Hero from "../components/hero"
import Author from "../components/author"
import ImageSlider from "../components/image-slider"
import StorySlider from "../components/story-slider"

const RaiseMoneyPage = () => {
  const { strapiAboutUs } = useStaticQuery(graphql`
    query {
      strapiAboutUs {
        hero {
          title
          callToAction
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: AUTO
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  aspectRatio: 0.0
                )
              }
            }
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 0.0, height: 250)
              }
            }
          }
          Buttons {
            strapi_id
            name
            link
          }
        }
        #seo {
        #  ...StrapiSeo
        #}
      }
    }
  `)

  //console.log("strapiAboutUs: ", strapiAboutUs)

  /*  const seo = {
    metaTitle: strapiAboutUs.seo.metaTitle,
    metaDescription: strapiAboutUs.seo.metaDescription,
    shareImage: strapiAboutUs.seo.shareImage,
  } */

  return (
    <>
      {/* <Seo seo={seo} /> */}
      <Hero hero={strapiAboutUs.hero} />
      <section className="uk-section-default">
        <Headings
          title="Who we are!"
          description="Welcome to our about us section, see who we are and why we do this!"
        />
        <div className="uk-container uk-margin">Main Content section</div>
      </section>
      <Author />
      <div className="uk-container uk-container-small">
        <hr className="uk-margin-remove" />
      </div>
      <section className="uk-section uk-article">
        <div className="uk-container uk-container-small">
          <h2 className="uk-text-bold uk-h1 uk-margin-remove-adjacent uk-margin-remove-top">
            Fusce facilisis tempus magna ac dignissim.
          </h2>
          <p className="uk-article-meta">
            Written on 12 April 2012. Posted in <a href="/">Blog</a> |{" "}
            <span data-uk-icon="icon: future"></span> Takes 7 min reading.
          </p>

          <p className="uk-text-lead">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip.
          </p>

          <p>
            UPDATE 24th October 15.10 BST — Vivamus sed consequat urna. Fusce
            vitae urna sed ante placerat iaculis. Suspendisse potenti.
            Pellentesque quis fringilla libero. In hac habitasse platea
            dictumst.
          </p>
        </div>
        <ImageSlider />

        {/* text */}
        <div className="uk-container uk-container-small">
          <p>
            Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae,
            ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam
            egestas semper. Aenean ultricies mi vitae est. Mauris placerat
            eleifend leo.
          </p>
          <blockquote cite="#">
            <p className="uk-margin-small-bottom">
              The blockquote element represents content that is quoted from
              another source, optionally with a citation which must be within a
              footer or cite element.
            </p>
            <footer>
              Someone famous in{" "}
              <cite>
                <a href="/">Source Title</a>
              </cite>
            </footer>
          </blockquote>
          <h3>Aenean ultricies mi vitae est.</h3>
          <p>
            Nulla facilisi. Mauris euismod metus eu augue dictum luctus. Aliquam
            tellus mi, venenatis sit amet laoreet non, laoreet sit amet dolor.
            Mauris feugiat sem sit amet tortor tristique, eu varius risus
            accumsan. Nullam tincidunt erat sed turpis suscipit, in lacinia mi
            venenatis. Proin in dui sed neque malesuada dictum vitae vitae elit.
            I
          </p>
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
            <li>Aliquam tincidunt mauris eu risus.</li>
            <li>Vestibulum auctor dapibus neque.</li>
          </ul>
          <p>
            Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae,
            ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam
            egestas semper. Aenean ultricies mi vitae est. Mauris placerat
            eleifend leo.
          </p>
        </div>
        {/* text */}
        <StorySlider />
      </section>
    </>
  )
}

export default RaiseMoneyPage
