import React from "react"

const StorySlider = ({ articles }) => {
  //console.log("Articles: ", articles)
  return (
    <div className="uk-container">
      <div data-uk-slider="velocity: 5">
        <div className="uk-position-relative">
          <div className="uk-slider-container removeOpacity">
            <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-grid-medium">
              {articles.map((article, index) => {
                return (
                  <li key={index}>
                    <div>
                      <div
                        className="uk-card uk-card-default uk-card-small"
                        style={{ boxShadow: "none", opacit: 1 }}
                      >
                        <div className="uk-card-media-top">
                          <a href="/">
                            <img
                              data-src="https://unsplash.it/620/350/?random=1"
                              width="620"
                              height="350"
                              data-uk-img="target: !.uk-slideshow-items"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="uk-card-header">
                          <div
                            className="uk-grid-small uk-flex-middle"
                            data-uk-grid="true"
                          >
                            <div className="uk-width-auto">
                              <img
                                className="uk-border-circle"
                                alt=""
                                width="40"
                                height="40"
                                src="https://unsplash.it/60/60/?random"
                              />
                            </div>
                            <div className="uk-width-expand">
                              <h6 className="uk-margin-remove-bottom uk-text-bold">
                                {article.author.name}
                              </h6>
                              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                                <time dateime="2016-04-01T19:00">
                                  April 01, 2016
                                </time>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="uk-card-body">
                          <h4 className="uk-margin-small-bottom uk-text-bold">
                            Sed do eiusmod tempor incididunt
                          </h4>
                          <p className="uk-text-small">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt.
                          </p>
                          <a
                            className="uk-button uk-button-text uk-text-bold uk-margin-small"
                            href="/"
                          >
                            READ MORE
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
          <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
          <div className="uk-hidden@m uk-dark">
            <a
              className="uk-position-center-left uk-position-small"
              href="/"
              aria-label="previous"
              data-uk-slidenav-previous="true"
              data-uk-slider-item="previous"
            >
              {null}
            </a>
            <a
              className="uk-position-center-right uk-position-small"
              href="/"
              aria-label="next"
              data-uk-slidenav-next="true"
              data-uk-slider-item="next"
            >
              {null}
            </a>
          </div>
          <div className="uk-visible@m">
            <a
              className="uk-position-center-left-out uk-position-small"
              href="/"
              aria-label="previous"
              data-uk-slidenav-previous="true"
              data-uk-slider-item="previous"
            >
              {null}
            </a>
            <a
              className="uk-position-center-right-out uk-position-small"
              href="/"
              aria-label="next"
              data-uk-slidenav-next="true"
              data-uk-slider-item="next"
            >
              {null}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StorySlider
