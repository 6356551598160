import React from "react"

const ImageSlider = () => {
  return (
    <div className="uk-container uk-section uk-padding-remove-top">
      <div
        className="uk-position-relative uk-visible-toggle uk-light"
        data-uk-slideshow="ratio: 7:3; animation: push; min-height: 270; velocity: 3"
      >
        <ul className="uk-slideshow-items">
          <li>
            <img
              data-src="https://picsum.photos/1200/550/?random=1"
              data-uk-img="target: !.uk-slideshow-items"
              alt=""
              data-uk-cover="true"
            />
            <div className="uk-position-bottom uk-position-medium uk-text-center uk-light">
              <h3 className="uk-margin-remove">Sed consequat urna.</h3>
              <p className="uk-margin-remove uk-visible@s">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </li>
          <li>
            <img
              data-src="https://picsum.photos/1200/550/?random=2"
              data-uk-img="target: !.uk-slideshow-items"
              alt=""
              data-uk-cover="true"
            />
            <div className="uk-position-bottom uk-position-medium uk-text-center uk-light">
              <h3 className="uk-margin-remove">Sed consequat urna.</h3>
              <p className="uk-margin-remove uk-visible@s">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </li>
          <li>
            <img
              data-src="https://picsum.photos/1200/550/?random=3"
              data-uk-img="target: !.uk-slideshow-items"
              alt=""
              data-uk-cover="true"
            />
            <div className="uk-position-bottom uk-position-medium uk-text-center uk-light">
              <h3 className="uk-margin-remove">Sed consequat urna.</h3>
              <p className="uk-margin-remove uk-visible@s">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </li>
        </ul>
        <a
          className="uk-position-center-left uk-position-small uk-hidden-hover"
          href="/"
          aria-label="previous"
          data-uk-slidenav-previous="ratio: 1.5"
          data-uk-slideshow-item="previous"
        >
          {null}
        </a>
        <a
          className="uk-position-center-right uk-position-small uk-hidden-hover"
          href="/"
          aria-label="next"
          data-uk-slidenav-next="ratio: 1.5"
          data-uk-slideshow-item="next"
        >
          {null}
        </a>
      </div>
    </div>
  )
}

export default ImageSlider
