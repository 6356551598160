import React from "react"
//import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Author = ({ author }) => {
  //console.log("Author:", author)
  return (
    <section className="uk-section uk-section-small  uk-padding-remove uk-margin-small-bottom">
      <div id="author-wrap" className="uk-container uk-container-small">
        <div
          className="uk-grid uk-grid-medium uk-flex uk-flex-middle"
          data-uk-grid="true"
        >
          <div className="uk-width-auto">
            {author?.avatar && (
              <GatsbyImage
                image={getImage(author.avatar.localFile)}
                alt={author.name}
                title={author.name}
                className="uk-border-circle"
                //style={{ width: 50, height: 50 }}
              />
            )}
          </div>
          <div className="uk-width-expand">
            <h4 className="uk-margin-remove uk-text-bold">
              {author.name} Article
            </h4>
            <span className="uk-text-small uk-text-muted">
              {author.position}
            </span>
          </div>
          <div className="uk-width-auto">
            <div className="uk-inline">
              <a
                href="/#"
                className="uk-icon-button"
                data-uk-icon="icon:more-vertical"
                aria-label="vertical button"
              >
                {null}
              </a>
              <div data-uk-dropdown="mode:click; pos: bottom-right; boundary:#author-wrap">
                <ul className="uk-nav uk-dropdown-nav">
                  <li className="uk-nav-header">Actions</li>
                  <li>
                    <a
                      href="/#"
                      alt="rate this author"
                      aria-label="rate this author"
                    >
                      Rate this author
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#"
                      alt="follow this author"
                      aria-label="follow this author"
                    >
                      Follow this author
                    </a>
                  </li>
                  <li>
                    <a href="/#" alt="bookmark" aria-label="bookmark">
                      Bookmark
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#"
                      alt="view more articles"
                      aria-label="view more articles"
                    >
                      View more articles
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Author
